import { Button, Card, CardActions, CircularProgress, TextField, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { setUserError, setUserNotice, updatePassword } from "../../rtk/features/auth/authSlice";

import { useEffect, useState } from "react";

const ResetPassword = () => {
  const { user, error, subLoading, notice } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setUserNotice(null));
    dispatch(setUserError(null));
    if (!confirmPassword || !confirmPassword) {
      dispatch(setUserError("Field empty!"));
    } else if (confirmPassword !== password) {
      dispatch(setUserError("Password does not matched!"));
    } else {
      console.log({ cri: user._id });
      dispatch(updatePassword({ userId: user._id, password, currentPassword }));
    }
  };

  useEffect(() => {
    dispatch(setUserError(null));
  }, [dispatch]);

  return (
    <Box>
      <Box className="bg-slate-200">
        <Typography className="text-center !text-3xl" variant="h6" noWrap component="div">
          Reset Password
        </Typography>
      </Box>
      <Box className="mt-10">
        <Box onSubmit={handleSubmit} component={"form"} noValidate className="w-full">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              // background: "url(https://source.unsplash.com/featured/1600x900)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Card sx={{ minWidth: 320, maxWidth: "320px" }}>
              <Box
                sx={{
                  marginTop: "1em",
                  display: "flex",
                  justifyContent: "center",
                  color: (theme) => theme.palette.grey[500],
                }}
              ></Box>
              <Box sx={{ padding: "0 1em 1em 1em" }}>
                <Box sx={{ marginTop: "0.2em" }}>
                  <TextField onChange={(e) => setCurrentPassword(e.target.value)} margin="normal" required fullWidth label="Current Password" type="password" autoFocus />
                  <TextField onChange={(e) => setPassword(e.target.value)} margin="normal" required fullWidth label="Password" type="password" autoFocus />
                  <TextField className={`text-red-600`} onChange={(e) => setConfirmPassword(e.target.value)} margin="normal" required fullWidth name="password" label="Confirm Password" type="password" id="password" autoComplete="current-password" />
                </Box>
                <Box sx={{ marginTop: "0.2em" }}></Box>

                <Box sx={{ marginTop: "1em" }}></Box>
              </Box>
              <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                <Button variant="contained" type="submit" color="primary" disabled={subLoading} fullWidth>
                  {subLoading && <CircularProgress size={20} thickness={5} />}
                  Update Password
                </Button>
              </CardActions>

              <Box className="text-center p-3">
                <Typography className="text-red-600" dangerouslySetInnerHTML={{ __html: error }}></Typography>
              </Box>

              {notice && (
                <Box className="text-center">
                  <Typography className="text-green-500 !text-xl !mb-5">{notice}</Typography>
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
