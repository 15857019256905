import "./App.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Header from "./components/Header/Header";
import Plugin from "./pages/plugin/Plugin";
import { useEffect } from "react";
import { verifyUserToken } from "./rtk/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./components/ProtectedRoute/PrivateRoute";
import Loader from "./components/Loader/Loader";
import PluginList from "./pages/plugin-list/PluginList";
import LoginHeader from "./components/Header/LoginHeader";
import MyAccount from "./pages/my-account/MyAccount";
import { Box } from "@mui/material";
import Register from "./pages/register/Register";
import SMTPSetup from "./pages/smtp-setup/SMTPSetup";
import VerifyEmail from "./pages/verify-email/VerifyEmail";
import PasswordReset from "./pages/password-reset/PasswordReset";

import "react-toastify/dist/ReactToastify.css";
import Users from "./pages/users/Users";
import Admin from "./Admin";
import Logs from "./pages/log/Logs";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/not-found/NotFound";
import Unsubscribe from "./landing-pages/unsubscribe/Unsubscribe";
function App() {
  const dispatch = useDispatch();
  const { user, loading, isLandingPage } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifyUserToken());
  }, [dispatch]);

  if (loading) {
    return (
      <Box className="flex w-full h-screen justify-center items-center">
        <Loader />
      </Box>
    );
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      {isLandingPage ? <></> : user?.emailVerified ? <Header /> : <LoginHeader />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/password-reset/:token" element={<PasswordReset />} />
        {/* <Redirect to="/admin" /> */}
        <Route
          path="/admin"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-account/:tab"
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/plugin/:platform/:id"
          element={
            <PrivateRoute>
              <Plugin />
            </PrivateRoute>
          }
        />
        <Route
          path="/plugin-list"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <PluginList />
            </PrivateRoute>
          }
        />
        <Route
          path="/smtp-setup"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <SMTPSetup />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/logs"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <Logs />
            </PrivateRoute>
          }
        />
        {/* Landing pages */}
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {isLandingPage ? <></> : <Footer />}
    </BrowserRouter>
  );
}

export default App;
