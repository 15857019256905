import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box className="flex justify-center items-center p-5" style={{ background: "#4527A4" }}>
      <Typography className="text-white text-center">Copyright &copy; {new Date().getFullYear()}. All Rights Reserved</Typography>
      {/* <Box>
      </Box> */}
      {/* <Box></Box> */}
    </Box>
  );
};

export default Footer;
