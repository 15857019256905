import axios from "../../../axios";
import { logout as pluginLogout } from "./../plugin/pluginSlice";
import { logout as pluginListLogout } from "./../pluginLIst/pluginListSlice";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import validateError from "../../../utils/validateError";

// initial state
const initialState = {
  user: null,
  users: [],
  loggedIn: false,
  loading: true,
  error: null,
  activeRole: "customer",
  freemiusUser: null,
  drawerOpen: false,
  subLoading: false,
  verificationProcessing: true,
  notice: null,
  tokenVerified: false,
  currentUserId: null,
  selectedUser: {},
  isLandingPage: false,
};

export const fetchUsers = createAsyncThunk("auth/fetchUsers", async () => {
  try {
    const { data } = await axios.get("/api/v1/users");
    return data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const fetchFreemiusUser = createAsyncThunk("auth/fetchFreemiusUser", async ({ pluginId, freemiusUserId, userId }) => {
  try {
    const response = await axios.get(`/api/v1/freemius/plugins/${pluginId}/users/${freemiusUserId}?user_id=${userId}&fields=email,public_key,secret_key,first,last`);
    return response.data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const verifyUserToken = createAsyncThunk("auth/verifyUserToken", async () => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    try {
      const response = await axios.get(`/api/v1/auth/verify-token?access_token=${access_token}`);
      return response.data.user;
    } catch (error) {
      throw new Error("Something went wrong");
    }
  } else {
    throw new Error();
  }
});

export const Login = createAsyncThunk("auth/login", async (credential) => {
  try {
    const { data } = await axios.post("/api/v1/auth/login", credential);
    localStorage.setItem("access_token", data?.token?.access_token);
    localStorage.setItem("refresh_token", data?.token?.refresh_token);
    return data;
  } catch (error) {
    const message = error.response?.data?.errorMessages?.[0]?.message || error?.response?.data?.message;
    throw new Error(message);
  }
});

export const Register = createAsyncThunk("auth/register", async (credential) => {
  try {
    const { data } = await axios.post("/api/v1/auth/register", credential);
    return data?.user;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const updateProfile = createAsyncThunk("auth/updateProfile", async ({ userId, info }) => {
  try {
    const { data } = await axios.put(`/api/v1/users/${userId}`, info);
    return data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const updatePassword = createAsyncThunk("auth/updatePassword", async (info) => {
  try {
    const { data } = await axios.put(`/api/v1/users/update-password`, info);
    return data?.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const verifyEmailToken = createAsyncThunk("auth/verifyEmailToken", async (token) => {
  try {
    const { data } = await axios.put(`/api/v1/mail/verify-email/${token}`);
    localStorage.setItem("access_token", data?.token?.access_token);
    localStorage.setItem("refresh_token", data?.token?.refresh_token);
    return data?.user;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const verifyPasswordResetToken = createAsyncThunk("auth/verifyPasswordResetLink", async (token) => {
  try {
    const { data } = await axios.get(`/api/v1/auth/verify-password-reset-link/${token}`);
    return data?.user?._id;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const sendVerificationEmail = createAsyncThunk("auth/sendVerificationEmail", async (userId) => {
  try {
    const { data } = await axios.post(`/api/v1/mail/send-verification-email/${userId}`);
    return data?.user;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const sendPasswordResetLink = createAsyncThunk("auth/sendPasswordResetLink", async (email) => {
  try {
    const response = await axios.post(`/api/v1/mail/send-password-reset-link/?email=${email}`);
    return response.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    setFreemiusUser: (state, action) => {
      state.freemiusUser = action.payload;
    },
    setUserRole: (state, action) => {
      state.activeRole = action.payload;
      localStorage.setItem("activeRole", action.payload);
    },
    userLogout: (state, action) => {
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state = initialState;
      pluginLogout();
      pluginListLogout();
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setUserNotice: (state, action) => {
      state.notice = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setIsLandingPage: (state, action) => {
      state.isLandingPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(verifyUserToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyUserToken.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(verifyUserToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.user = null;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      });

    // login
    builder
      .addCase(Login.pending, (state, action) => {
        state.subLoading = true;
        state.error = null;
        state.notice = null;
      })
      .addCase(Login.fulfilled, (state, action) => {
        state.notice = null;
        state.subLoading = false;
        if (action.payload.success) {
          state.user = action.payload.user;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(Login.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
        state.notice = null;
      });

    // register
    builder
      .addCase(Register.pending, (state) => {
        state.subLoading = true;
        state.notice = null;
        state.error = null;
      })
      .addCase(Register.fulfilled, (state) => {
        state.subLoading = false;
        state.user = null;
        state.error = null;
      })
      .addCase(Register.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });

    // fetch freemius user
    // builder.addCase(Login.pending, (state, action) => {
    //   // state.loading = true;
    //   state.error = null;
    // });
    builder.addCase(fetchFreemiusUser.fulfilled, (state, action) => {
      state.loading = false;
      state.freemiusUser = action.payload;
    });
    // builder.addCase(Login.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = "Invalid Email/Password!";
    // });

    builder
      .addCase(updateProfile.pending, (state, action) => {
        state.subLoading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.subLoading = false;
        if (state.user._id === action.payload._id) {
          state.user = action.payload;
        } else {
          state.users = state.users.map((item) => (item._id === action.payload._id ? action.payload : item));
        }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });

    // verify email token
    builder
      .addCase(verifyEmailToken.pending, (state) => {
        state.error = null;
        state.verificationProcessing = true;
      })
      .addCase(verifyEmailToken.fulfilled, (state, action) => {
        state.verificationProcessing = false;
        state.tokenVerified = true;
        state.user = action.payload;
      })
      .addCase(verifyEmailToken.rejected, (state, action) => {
        state.verificationProcessing = false;
        state.error = action.error.message;
      });

    builder
      .addCase(sendVerificationEmail.pending, (state) => {
        state.error = null;
        state.subLoading = true;
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.subLoading = false;
        state.notice = "Verification Email Sent!";
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });

    // sendPasswordResetLink
    builder
      .addCase(sendPasswordResetLink.pending, (state) => {
        state.error = null;
        state.notice = null;
        state.subLoading = true;
      })
      .addCase(sendPasswordResetLink.fulfilled, (state, action) => {
        state.subLoading = false;
        state.notice = "Password Reset Link Sent!";
      })
      .addCase(sendPasswordResetLink.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });

    // verifyPasswordResetLink
    builder
      .addCase(verifyPasswordResetToken.pending, (state) => {
        state.error = null;
        state.notice = null;
        state.verificationProcessing = true;
        state.tokenVerified = false;
      })
      .addCase(verifyPasswordResetToken.fulfilled, (state, action) => {
        state.verificationProcessing = false;
        state.tokenVerified = true;
        state.currentUserId = action.payload;
        // state.notice = "Password Reset Link Sent!";
      })
      .addCase(verifyPasswordResetToken.rejected, (state, action) => {
        state.tokenVerified = false;
        state.verificationProcessing = false;
        state.error = action.error.message;
      });

    // updatePassword
    builder
      .addCase(updatePassword.pending, (state) => {
        state.error = null;
        state.notice = null;
        state.subLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.error = null;
        state.passwordUpdated = true;
        state.notice = "Password updated successfully!";
        state.subLoading = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.error = action.error.message;
        state.subLoading = false;
      });
  },
});

export default authSlice.reducer;

export const { setUser, setUserRole, userLogout, setUserError, logout, setDrawerOpen, setUserNotice, setSelectedUser, setIsLandingPage } = authSlice.actions;
