import { combineReducers, configureStore } from "@reduxjs/toolkit";

import userSlice from "../features/user/userSlice";
import pluginSlice from "../features/plugin/pluginSlice";
import pluginListReducer from "../features/pluginLIst/pluginListSlice";
import optionSlice from "../features/option/optionSlice";
import logSlice from "../features/log/logSlice";
import authSlice from "../features/auth/authSlice";

const store = configureStore({
  reducer: combineReducers({
    user: userSlice,
    plugin: pluginSlice,
    pluginList: pluginListReducer,
    option: optionSlice,
    log: logSlice,
    auth: authSlice,
  }),
});

export default store;
