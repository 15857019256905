const validateError = (error) => {
  let message = "";
  const messages = error.response?.data?.errorMessages;
  if (messages?.length) {
    messages.map((item) => {
      if (item.message.trim()) {
        message += `${item.message} <br />`;
      }
      return null;
    });
  } else {
    message = error.response?.data?.message;
  }
  return message;
};

export default validateError;
