import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function LoginHeader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#4527A4" }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ display: { xs: "none", sm: "block" } }}>
            Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box className="flex justify-center ml-5 flex-col items-end mr-3">
              <Link to="/login">
                <Typography>Login</Typography>
              </Link>
            </Box>
            <Box className="flex justify-center ml-5 flex-col items-end mr-3">
              <Link to="/register">
                <Typography>Register</Typography>
              </Link>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box className="flex"></Box>
    </Box>
  );
}
