import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sidebar from "../../components/Sidebar/Sidebar";

import { fetchLogs } from "../../rtk/features/log/logSlice";
import LogsTable from "./LogsTable";
import Loader from "../../components/Loader/Loader";

const Logs = () => {
  const { logs, loading } = useSelector((state) => state.log);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLogs());
  }, [dispatch]);

  console.log(logs);
  return (
    <Box className="flex flex-row">
      <Box>
        <Sidebar />
      </Box>
      <Box className="flex-auto p-3 pt-5">
        <Box className="text-center">
          <h2 className="text-3xl">Logs</h2>

          {loading ? (
            <Box className="flex justify-center items-center h-72">
              <Loader />
            </Box>
          ) : (
            <LogsTable />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Logs;
