import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { sendVerificationEmail, setSelectedUser } from "../../rtk/features/auth/authSlice";
import { updateProfile } from "../../rtk/features/user/userSlice";
import BasicModal from "../../components/Modal/Modal";
import UserDetails from "./UserDetails";

const columns = [{ id: "name", label: "Name", minWidth: 170 }, { id: "email", label: "Email", minWidth: 100 }, { id: "isVerificationEmailSent", label: "Verification Email Sent", minWidth: 80 }, { label: "Verified" }, { label: "Created At" }, { label: "Action" }];

export default function UsersTable() {
  const { users, subLoading} = useSelector((state) => state.user);
  const { user, notice, subLoading:mailSending, error, selectedUser } = useSelector((state) => state.auth);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = React.useState();
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  const [open, setOpen] = React.useState(false);
  // const [selectedUser, setSelectedUser] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDisable = (user) => {
    if (window.confirm("Are you sure")) {
      dispatch(setSelectedUser(user));
      dispatch(updateProfile({ userId: user._id, info: { isDisabled: !user.isDisabled } }));
    }
  };

  const handleResendEmail = (user) => {
    console.log(user)
    dispatch(setSelectedUser(user));
    dispatch(sendVerificationEmail(user._id));
  }

  React.useEffect(() => {
    if (searchQuery) {
      setFilteredUsers(
        users.filter((user) => {
          const name = user.name.firstName + " " + user.name.lastName;
          return user.email.toLowerCase().includes(searchQuery.toLowerCase()) || name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
    } else {
      setFilteredUsers(users);
    }
  }, [searchQuery, users]);

  console.log({mailSending, selectedUser})

  return (
    <Box className="!mt-10">
        <Typography>{notice} </Typography>
      <Box className="text-right !mb-3" sx={{ maxWidth: "1150px", margin: "0 auto", width: "100%" }}>
        <TextField className="" name="searchQuery" placeholder="Search" label="Search" onChange={(e) => setSearchQuery(e.target.value)} type="text" />
      </Box>
      <Paper sx={{ maxWidth: "1100px", margin: "0 auto", width: "100%", overflow: "hidden" }}>
        <TableContainer className="text-left" sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={user._id}>
                  <TableCell>{user.name.firstName + " " + user.name.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.isVerificationEmailSent ? "Yes" : "No"}</TableCell>
                  <TableCell>{user.emailVerified ? "Yes" : "No"}</TableCell>
                  <TableCell>{formatDate(new Date(user.createdAt), "Y-m-d")}</TableCell>
                  <TableCell>
                    
                    {selectedUser._id === user._id && subLoading && <CircularProgress size={20} thickness={5} />}
                    <Button className={`!mr-2 ${user.isDisabled ? "" : "!bg-red-500 !text-slate-50"}`} disabled={subLoading} variant="outlined" size="small" onClick={() => handleDisable(user)}>
                      {user.isDisabled ? "Enable" : "Disable"}
                    </Button>
                    {mailSending && selectedUser._id === user._id && <CircularProgress className='mr-2 ' size={20} thickness={5} />}
                    <Button
                      disabled={mailSending && selectedUser._id === user._id}
                      variant="contained"
                      onClick={() => handleResendEmail(user)}
                      size="small"
                    >
                      <SendIcon />
                    </Button>
                    <Button
                    className='!ml-2'
                      // disabled={selectedUser._id === user._id && subLoading}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleOpen();
                        dispatch(setSelectedUser(user));
                      }}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={users.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
      <BasicModal width="900px" className="max-w-full" handleClose={handleClose} handleOpen={handleOpen} open={open}>
        <UserDetails handleClose={handleClose} />
      </BasicModal>
    </Box>
  );
}
