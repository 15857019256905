import { Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <img
        style={{ overflow: "hidden", boxZizing: "border-box", height: " 100%", width: "100%", objectFit: "cover" }}
        src="https://static.vecteezy.com/system/resources/thumbnails/008/249/822/original/a-white-robot-searching-for-a-404-error-with-a-torch-light-4k-animation-404-page-not-found-error-concept-with-a-robot-4k-footage-system-finding-404-error-with-a-big-torch-light-animated-free-video.jpg"
        alt=""
      />
    </div>
  );
};

export default NotFound;
