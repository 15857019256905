import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPlugins } from "../../rtk/features/plugin/pluginSlice";
import UserPluginsTable from "./UserPluginsTable";
export default function UserDetails({ handleClose }) {
  const { selectedUser } = useSelector((state) => state.auth);
  const { allPlugins, loading } = useSelector((state) => state.plugin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allPlugins[selectedUser?._id]) {
      dispatch(fetchUserPlugins(selectedUser?._id));
    }
  }, [selectedUser, dispatch, allPlugins]);

  if (loading) {
    return (
      <Box>
        <h2>Loading...</h2>
      </Box>
    );
  }
  return (
    <Box>
      <Box>
        <UserPluginsTable />
      </Box>
    </Box>
  );
}
