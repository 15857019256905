import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validateError from "../../../utils/validateError";
import axios from "../../../axios";

const initialState = {
  logs: [],
  loading: false,
  subLoading: false,
  notice: null,
  error: null,
};

export const fetchLogs = createAsyncThunk("log/fetchLogs", async () => {
  try {
    const { data } = await axios.get("/api/v1/logs");
    return data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const addLog = createAsyncThunk("log/addLog", async (log) => {
  try {
    const { data } = await axios.post("/api/v1/logs", log);
    return data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const deleteLog = createAsyncThunk("log/deleteLog", async (id) => {
  try {
    await axios.delete(`/api/v1/logs/${id}`);
    return id;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.logs = action.payload;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // add log
    builder
      .addCase(addLog.pending, (state) => {
        state.subLoading = true;
        state.error = null;
      })
      .addCase(addLog.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
        state.logs.push(action.payload);
      })
      .addCase(addLog.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });

    // Delete log
    builder
      .addCase(deleteLog.pending, (state) => {
        state.subLoading = true;
        state.error = null;
      })
      .addCase(deleteLog.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
        state.logs = state.logs.filter((log) => log._id !== action.payload);
      })
      .addCase(deleteLog.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });
  },
});

export default logSlice.reducer;
