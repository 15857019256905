import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../rtk/features/user/userSlice";
import { Box } from "@mui/material";
import Sidebar from "../../components/Sidebar/Sidebar";
import UsersTable from "./UsersTable";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Box className="flex flex-row">
      <Box>
        <Sidebar />
      </Box>
      <Box className="flex-auto p-3 pt-5">
        <Box className="text-center">
          <h2 className="text-3xl">Users</h2>
          <UsersTable />
        </Box>
      </Box>
    </Box>
  );
};

export default Users;
