import { Fragment, useEffect, useState, version } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextareaAutosize, Typography } from "@mui/material";
import { addPluginListItem, setPluginListError, setPluginListNotice, setPluginListVersionLoading, updatePluginListItem } from "../../rtk/features/pluginLIst/pluginListSlice";
import SimpleLoader from "../../components/Loader/SimpleLoader";
import { produce } from "immer";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import validateError from "../../utils/validateError";
import CloseIcon from "@mui/icons-material/Close";

export default function PluginListForm({ formData, handleClose }) {
  const { currentId, loading, error, subLoading, versionLoading, notice } = useSelector((state) => state.pluginList);
  const [data, setData] = useState(formData || { name: "", tableName: "", folderName: "", permalinks: [], versions: [] });
  const { name, IDs = [], tableName, permalinks, folderName, docsURL, demoURL, icon = {}, versions = [] } = data;
  const [versionData, setVersionData] = useState({ version: "", downloadSlug: "", enabled: false });
  const [override, setOverride] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name.includes(".")) {
      const steps = e.target.name.split(".");
      const tempVar = { ...data[steps[0]], [steps[1]]: e.target.value };
      setData({ ...data, [steps[0]]: tempVar });
    } else {
      setData({ ...data, [e.target.name]: ["permalinks", "IDs"].includes(e.target.name) ? e.target.value.split(",").map((item) => item.trim()) : e.target.value });
    }
  };

  const handleAdd = () => {
    dispatch(addPluginListItem(data));
  };

  const handleUpdate = () => {
    const { _id, createdAt, updatedAt, _v, ...finalData } = data;
    dispatch(
      updatePluginListItem({
        id: currentId,
        data: finalData,
      })
    );
  };

  const handleVersionAdd = async () => {
    const { index, ...version } = versionData;
    const pluginFile = document.getElementById("pluginFile")?.files?.[0];

    if (!version.version || !version.downloadSlug) {
      return dispatch(setPluginListError("Required field empty!"));
    }

    if (!pluginFile) {
      return dispatch(setPluginListError("Please upload a plugin!"));
    }

    if (typeof index !== "number" && versions.find((item) => item.version === version.version)) {
      return dispatch(setPluginListError("Version already exists"));
    }

    dispatch(setPluginListNotice(null));
    dispatch(setPluginListVersionLoading(true));
    dispatch(setPluginListError(null));
    setOverride(false);

    try {
      const response = await axios.post(
        "https://api.bplugins.com/wp-json/media-upload/v1/plugin-upload",
        {
          "plugin-file": pluginFile,
          folder: folderName,
          file_name: version.downloadSlug,
          override: override,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(setPluginListVersionLoading(false));
      const draftData = produce(data, (draft) => {
        if (typeof index === "number") {
          draft.versions[index] = version;
        } else {
          draft.versions.push(version);
        }
        setVersionData({ version: "", downloadSlug: "", enabled: false });
      });
      setData(draftData);
      dispatch(setPluginListNotice(typeof index === "number" ? "Version Updated successfully" : "Version Added Successfully"));
    } catch (error) {
      dispatch(setPluginListError(validateError(error)));
      dispatch(setPluginListVersionLoading(false));
      if (error?.response?.data?.code === "fileExistsError") {
        setOverride(true);
      }
    }
  };

  const handleVersionEnable = (index) => {
    const newData = produce(data, (draft) => {
      draft.versions[index].enabled = !draft.versions[index].enabled;
    });
    setData(newData);
  };

  const handleVersionDelete = async (index) => {
    if (window.confirm("Are you sure?")) {
      dispatch(setPluginListVersionLoading(true));
      dispatch(setPluginListError(null));
      dispatch(setPluginListNotice(null));
      const selectedVersion = versions[index];
      try {
        const response = await axios.post("https://api.bplugins.com/wp-json/media-upload/v1/plugin-delete", {
          folder: folderName,
          file_name: selectedVersion.downloadSlug,
        });
        dispatch(setPluginListVersionLoading(false));
        const newData = produce(data, (draft) => {
          draft.versions.splice(index, 1);
        });
        setData(newData);
      } catch (error) {
        dispatch(setPluginListVersionLoading(false));
        dispatch(setPluginListError(validateError(error)));
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <Box className="flex gap-5">
        <TextField fullWidth id="fullWidth" label="Name" name="name" value={name} onChange={handleChange} />
        <TextField fullWidth id="outlined-uncontrolled" label="Database Table Name" name="tableName" onChange={handleChange} value={tableName} />
      </Box>

      <Box className="flex gap-5 pt-5">
        <TextField fullWidth id="outlined-uncontrolled" label="Permalinks" name="permalinks" onChange={handleChange} value={permalinks?.join(",")} />
        <TextField fullWidth id="outlined-uncontrolled" label="Folder Name" name="folderName" onChange={handleChange} value={folderName} />
      </Box>

      <Box className="flex gap-5 pb-5">
        <TextField fullWidth label="Documentation URL" name="docsURL" onChange={handleChange} value={docsURL}></TextField>
        <TextField fullWidth label="Demo URL" name="demoURL" onChange={handleChange} value={demoURL}></TextField>
      </Box>

      {/* Version start  */}
      <FormGroup>
        <h3 className="text-3xl font-semibold">Versions: {versionLoading && <CircularProgress size={20} thickness={5} />}</h3>
        <Box className={`${versionLoading ? "opacity-50 pointer-events-none" : ""}`}>
          {Array.isArray(versions) && (
            <>
              {versions?.length ? (
                versions.map((version, index) => (
                  <Fragment key={index}>
                    <FormControlLabel control={<Checkbox checked={version.enabled} onClick={() => handleVersionEnable(index)} />} label={version.version} />
                    <EditIcon onClick={() => setVersionData({ ...version, index })} className="mr-1 -ml-3 cursor-pointer" fontSize="small" />
                    <DeleteIcon disabled={versionLoading} onClick={() => handleVersionDelete(index)} className="mr-5  cursor-pointer text-red-500" fontSize="small" />
                  </Fragment>
                ))
              ) : (
                <p>No Version Added yet!</p>
              )}
            </>
          )}
        </Box>
      </FormGroup>
      <Box className="flex gap-5 pb-5">
        <TextField className="w-96" label="Ex: 2.2.1" name="version" onChange={(e) => setVersionData({ ...versionData, version: e.target.value })} value={versionData.version}></TextField>
        <TextField type="file" fullWidth name="file" id="pluginFile"></TextField>
        <TextField fullWidth label="Download Slug" name="downloadSlug" value={versionData.downloadSlug} onChange={(e) => setVersionData({ ...versionData, downloadSlug: e.target.value })}></TextField>

        <Button size="small" variant="contained" onClick={handleVersionAdd} disabled={versionLoading} className={`${override ? "w-96" : "w-60"}`}>
          {versionLoading && <CircularProgress size={20} thickness={5} />}
          {override ? "Override" : typeof versionData.index === "number" ? "Edit" : "Add"}
        </Button>
        {override && (
          <Button
            variant="outlined"
            onClick={() => {
              setOverride(false);
              setVersionData({ version: "", downloadSlug: "", enabled: false });
            }}
          >
            <CloseIcon className="text-red-500" />
          </Button>
        )}
      </Box>
      {/* Version End  */}

      <label>Product/Plugin IDs (separate by comma(,))</label>
      <TextareaAutosize style={{ border: "1px solid #ddd" }} minRows={3} id="outlined-uncontrolled" value={IDs?.join(",")} onChange={handleChange} name="IDs" />
      <Box></Box>
      <Box className="flex gap-5"></Box>
      <TextField label="Icon image src" minRows={2} id="outlined-uncontrolled" value={icon?.img} onChange={handleChange} name="icon.img" />

      <label>Icon svg</label>
      <TextareaAutosize style={{ border: "1px solid #ddd" }} minRows={2} id="outlined-uncontrolled" value={icon?.svg} onChange={handleChange} name="icon.svg" />

      <Box></Box>
      <Box className="flex justify-between items-center mt-5">
        <Box>
          <Typography className="text-red-500"> {error}</Typography>
          <Typography className="text-green-500"> {notice}</Typography>
        </Box>
        <Box className="flex items-center">
          <Button variant="outlined" className="!mr-3" onClick={handleClose}>
            Cancel
          </Button>
          {(versionLoading || subLoading) && <SimpleLoader width={22} />}
          {currentId ? (
            <Button disabled={versionLoading || subLoading} onClick={handleUpdate} variant="contained">
              Update
            </Button>
          ) : (
            <Button disabled={versionLoading || subLoading} variant="contained" onClick={handleAdd}>
              Add
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
