import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLandingPage } from "../../rtk/features/auth/authSlice";
import SVGLogo from "../../icons/SVGLogo";

import "./style.css";
import useQuery from "../../hooks/useQuery";
import useAxios from "../../hooks/useAxios";

const capitalizeSentence = (text) => {
  return text?.replace(/(^\w)|(\.\s+\w)/g, function (match) {
    return match.toUpperCase();
  });
};

const Unsubscribe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [alreadyUnsubscribed, setAllreadyUnsubscribed] = useState(true);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [subscribeAgain, setSubscribeAgain] = useState(false);
  const { plugin_slug, plugin_id, email, user_id } = useQuery();
  const { data, isLoading, error, request } = useAxios(
    "https://api.bplugins.com/wp-json/data/v1/accept-data",
    { plugin_slug, email },
    { method: "post" }
  );

  const {
    data: fData,
    isLoading: fIsLoading,
    error: fError,
    request: fRequest,
  } = useAxios(
    `https://api.bplugins.com/wp-json/freemius/v1/marketing-allowed/plugins/${plugin_id}/users/${user_id}.json/${Date.now()}`,
    {},
    { method: "GET" }
  );

  useEffect(() => {
    dispatch(setIsLandingPage(true));
    if (plugin_slug) {
      request();
    }
    if (plugin_id) {
      fRequest();
    }
  }, [dispatch]);

  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    if (plugin_slug) {
      request({
        plugin_slug,
        email,
        marketing_allowed: false,
      });
    } else {
      fRequest({ is_marketing_allowed: false }, { method: "PUT" });
    }
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setSubscribeAgain(true);
    console.log({ plugin_slug, plugin_id });
    if (plugin_slug) {
      request({
        plugin_slug,
        email,
        marketing_allowed: true,
      });
    } else {
      fRequest({ is_marketing_allowed: true }, { method: "PUT" });
    }
  };

  useEffect(() => {
    if (!isLoading && !error && data && !data.data?.marketing_allowed) {
      setUnsubscribed(true);
    }
    if (!fIsLoading && !fError && fData && !fData.data?.is_marketing_allowed) {
      setUnsubscribed(true);
    }

    if (data || fData) {
      if (!pageLoaded) {
        setAllreadyUnsubscribed(
          !(data?.data?.marketing_allowed || fData?.data?.is_marketing_allowed)
        );
      }
      setPageLoaded(true);
    }
  }, [isLoading, error, data, fIsLoading, fData, fError, pageLoaded]);

  if ((!plugin_id || !user_id) && (!plugin_slug || !email)) {
    navigate("/");
  }

  if (!pageLoaded) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h3 className="text-3xl">Loading...</h3>
      </div>
    );
  }

  if (
    (alreadyUnsubscribed && !subscribeAgain) ||
    (alreadyUnsubscribed && (isLoading || fIsLoading))
  ) {
    return (
      <div className="flex items-center justify-center h-screen flex-col">
        <h3 className="text-3xl">Already Unsubscribed</h3>
        <div className="action mt-5">
          <button
            disabled={isLoading || fIsLoading}
            onClick={handleSubscribe}
            className="border text-xl bg-purple-900 text-white px-5 py-2 rounded cursor-pointer hover:bg-purple-800 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Subscribe Again
          </button>
        </div>
      </div>
    );
  }

  if (subscribeAgain && !isLoading && !fIsLoading) {
    return (
      <div className="flex items-center justify-center h-screen flex-col">
        <h3 className="text-3xl text-green-500">
          Successfully Subscribed Again
        </h3>
      </div>
    );
  }

  return (
    <div
      id="unsubscribe-page"
      className="flex py-10 my-5 items-center flex-col"
    >
      <div className="logo mb-12">
        <SVGLogo width="300px" />
      </div>
      {unsubscribed ? (
        <>
          <h3 className="text-4xl my-6 font-semibold">Unsubscribe Success</h3>
          <p>
            You have been successfully unsubscribed from "Marketing" emails for
            the following plugin:
          </p>
          <h3 className="text-2xl mt-3">
            {fData?.data?.plugin ||
              capitalizeSentence(data?.data?.plugin_slug?.replaceAll("-", " "))}
          </h3>
        </>
      ) : (
        <>
          <h3 className="text-5xl my-6 font-semibold">Unsubscribe</h3>
          <p className="text-2xl">
            Are yo sure you want to unsubscribe from all email communication?
          </p>
          <div className="action mt-5">
            <button
              disabled={isLoading || fIsLoading}
              onClick={handleUnsubscribe}
              className="border text-xl bg-purple-900 text-white px-5 py-2 rounded cursor-pointer hover:bg-purple-800 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Yes, unsubscribe me
            </button>
          </div>
          {error && <p>{error.message}</p>}
        </>
      )}
    </div>
  );
};

export default Unsubscribe;
